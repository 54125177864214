/** GLOBAL TARGETING */

/** Object keys for global ad targeting */
export const adGlobalKeyNames = {
  address: 'address',
  apt: 'apt',
  city: 'city',
  hashedEmail: 'hashed_email',
  listPrice: 'listPrice',
  state: 'state',
  optimization: 'Optimization_Test',
  propertyType: 'propertyType',
  /** used for ads */
  zip: 'zip',
  /** used for text links */
  zipcode: 'zipcode',
};

/** Event names we pass to cordless data event function */
export const dataEventNames = {
  /** an inquiry submitted event, on a page with no distinction of sale/rent (e.g. contact.php) */
  default: 'default',
  /** an inquiry submitted event, on a page with property(s) for sale */
  inquiryBuyPage: 'moreInfoRequestBuy',
  /** an inquiry submitted event, on a page with property(s) for rent */
  inquiryRentPage: 'moreInfoRequestRent',
};

/** AD ID COLLECTIONS */

/** All supported cordless ad ids
 * ROS = Run Of Site
 * BTF = Below The Fold
 * LDP = Listing Detail Page
 * Mobile = Ads show only on mobile screens 936px and below
 * SRP = Search Results Page (community pages)
 * Agents = Ads targeted specifically to real estate agents
 */
export const adUnitIds = {
  homepageStickyFooter970X90: 'div-gpt-ad-1710176930554-0',
  homepageContent970X90: 'div-gpt-ad-1710177071226-0',
  srpBtf970X90: 'div-gpt-ad-1711147543468-0',
  srpBtf970X90B: 'div-gpt-ad-1715270413316-0',
  srpCommunityNativeB: 'div-gpt-ad-1710176647896-0',
  srpCommunityNativeC: 'div-gpt-ad-1710176668945-0',
  srpCommunityNativeD: 'div-gpt-ad-1710176688485-0',
  srpCommunityNativeE: 'div-gpt-ad-1710176706616-0',
  srpCommunityNativeF: 'div-gpt-ad-1710176728910-0',
  srpCommunityNativeG: 'div-gpt-ad-1710176751449-0',
  srpStickyFooter970X90: 'div-gpt-ad-1710176614683-0',
  ldpBtf970X90: 'div-gpt-ad-1710176906440-0',
  ldpLeftRail300X250A: 'div-gpt-ad-1711147368472-0',
  ldpLeftRail300X250B: 'div-gpt-ad-1711147406291-0',
  ldpMobileA: 'div-gpt-ad-1720044645103-0',
  ldpMobileB: 'div-gpt-ad-1720044708693-0',
  ldpMobileC: 'div-gpt-ad-1720044775163-0',
  ldpStickyFooter970X90: 'div-gpt-ad-1710176864963-0',
  ldpStickySidebar300X250A: 'div-gpt-ad-1710176827428-0',
  ldpStickySidebar300X250B: 'div-gpt-ad-1710176803911-0',
  ldpPhotoCarousel728X90A: 'div-gpt-ad-1711147634919-0',
  ldpPhotoCarousel728X90B: 'div-gpt-ad-1711148069707-0',
  ldpPhotoCarousel728X90C: 'div-gpt-ad-1711148208078-0',
  ldpPhotoCarousel728X90D: 'div-gpt-ad-1711148246867-0',
  ldpPhotoCarousel728X90E: 'div-gpt-ad-1711148350466-0',
  ldpPhotoCarousel728X90F: 'div-gpt-ad-1711148385266-0',
  ldpPhotoCarousel728X90G: 'div-gpt-ad-1711148415486-0',
  ldpPhotoCarousel728X90H: 'div-gpt-ad-1711148443245-0',
  ldpPhotoCarousel728X90I: 'div-gpt-ad-1711148471173-0',
  ldpPhotoCarousel728X90J: 'div-gpt-ad-1711400179300-0',
  rosStickyFooter: 'div-gpt-ad-1710176777976-0',
  agentsStickyFooter970X90: 'div-gpt-ad-1710177169914-0',
  agentsStickySideBar300X250: 'div-gpt-ad-1711147311606-0',
};

/** All support cordless ad ids in an array, formatted for id css selector */
export const adUnitSelectors = Object.values(adUnitIds).map(aui => `#${aui}`).join(',');

/** All photo carousel ad ids */
export const ldpPhotoCarouselAdUnitIds = [
  adUnitIds.ldpPhotoCarousel728X90A,
  adUnitIds.ldpPhotoCarousel728X90B,
  adUnitIds.ldpPhotoCarousel728X90C,
  adUnitIds.ldpPhotoCarousel728X90D,
  adUnitIds.ldpPhotoCarousel728X90E,
  adUnitIds.ldpPhotoCarousel728X90F,
  adUnitIds.ldpPhotoCarousel728X90G,
  adUnitIds.ldpPhotoCarousel728X90H,
  adUnitIds.ldpPhotoCarousel728X90I,
  adUnitIds.ldpPhotoCarousel728X90J,
];

/** All listing page sticky sidebar ad ids */
export const ldpStickySidebarAdUnitIds = [
  adUnitIds.ldpStickySidebar300X250A,
  adUnitIds.ldpStickySidebar300X250B,
];

/** All community page ad ids, within the community-list */
export const srpCommunityAdUnitIds = [
  adUnitIds.srpCommunityNativeB,
  adUnitIds.srpCommunityNativeC,
  adUnitIds.srpCommunityNativeD,
  adUnitIds.srpCommunityNativeE,
  adUnitIds.srpCommunityNativeF,
  adUnitIds.srpCommunityNativeG,
];

/** All sticky footer ad ids */
export const stickyFooterAdUnitIds = [
  adUnitIds.agentsStickyFooter970X90,
  adUnitIds.homepageStickyFooter970X90,
  adUnitIds.srpStickyFooter970X90,
  adUnitIds.ldpStickyFooter970X90,
  adUnitIds.rosStickyFooter,
];

/** All ad ids that are loaded lazily */
export const lazyAdUnits = [
  ...ldpPhotoCarouselAdUnitIds,
  ...ldpStickySidebarAdUnitIds,
  ...srpCommunityAdUnitIds,
  adUnitIds.ldpMobileA,
];

/** LAZY OPTIMIZATION */

/**
 * Used for testing which lazy load pixel offset we should use for lazy loading ads.
 * The key is what we pass into global targetting for tracking, and the offset is how big the
 * intersection root margin is
 */
export const lazyAdPixelOffsets = [
  { key: 'P1', offset: '100px'},
  { key: 'P2', offset: '150px'},
  { key: 'P3', offset: '250px'},
  { key: 'P4', offset: '325px'},
  { key: 'P5', offset: '400px'},
  { key: 'P6', offset: '500px'},
  { key: 'P7', offset: '650px'},
  { key: 'P8', offset: '1000px'},
  { key: 'P9', offset: '1250px'},
  { key: 'P10', offset: '1500px'},
  { key: 'P11', offset: '1800px'},
];

export const srpCommunityLazyAdPixelOffsets = lazyAdPixelOffsets.filter((lapo) => ['P2', 'P3', 'P4', 'P5', 'P6', 'P7'].includes(lapo.key));
export const ldpMobileAAdPixelOffsets = lazyAdPixelOffsets.filter((lapo) => ['P1', 'P2', 'P3', 'P4', 'P5'].includes(lapo.key));
export const ldpPhotoCarouselAdPixelOffsets = lazyAdPixelOffsets.filter((lapo) => ['P7', 'P8', 'P9', 'P10', 'P11'].includes(lapo.key));

/** TEXT LINK IDS */

export const textLinkIds = {
  cmLink1: {
    elementId: 'cmlink1',
    name: '44092_textLink_01',
  },
  cmLink2: {
    elementId: 'cmlink2',
    name: '44092_textLink_02',
  },
  cmLink3: {
    elementId: 'cmlink3',
    name: '44092_textLink_03',
  },
  cmLink4: {
    elementId: 'cmlink4',
    name: '44092_textLink_04',
  }
};

export const textLinkSelectors = Object.values(textLinkIds).map((tli) => `#${tli.elementId}`).join(',');
