import { useCookieUniversal } from '~/composables/use-cookie-universal';
import { useStore } from '~/composables/use-store';
import { useAppleAuthService } from '~/composables/use-apple-auth-service';
import { RequestTimer } from '~/services/models/request-timer';
import { actionTypes, authMethods } from '~/utils/analytics';
import { cookieNames } from '~/utils/cookies';

/**
 * This plugin injects the appleAuth object into the context. This object adds the Apple Auth functionality to the app
 * by loading the Apple Auth script and initializing the Apple Auth object.
 */
export default defineNuxtPlugin({
  parallel: true,
  setup() {
    /**
     * Add the Apple Auth script to the page and initialize the Apple Auth object when the script is loaded.
     */
    function addScript() {
      try {
        const script = document.createElement('script');
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        script.async = true;
        script.defer = true;
        script.onload = init;
        document.head.appendChild(script);
      } catch (e) {
        logErrorToSentry('addScript', e);
      }
    }

    const redirectURI = window.location.origin;

    /**
     * Initialize the Apple Auth object using the client ID
     */
    function init() {
      const appleAuthClientId = process.env.appleAuthClientId;

      // eslint-disable-next-line no-undef
      AppleID.auth.init({
        clientId : appleAuthClientId,
        scope : 'name email',
        redirectURI,
        usePopup : true,
      });
    }

    async function signIn() {
      const store = useStore();
      const cookies = useCookieUniversal();
      const nuxtApp = useNuxtApp();

      try {
        // eslint-disable-next-line no-undef
        const response = await AppleID.auth.signIn();

        store.dispatch('loading/start');

        const result = await loginWithApi(response);

        // Store the Feathers JWT in a cookie
        cookies.set(cookieNames.feathersJwt, result.accessToken, {
          path: '/',
        });

        // send a CTA event that auth was successful
        nuxtApp.$bexGtag.sendCtaEvent({
          id: authMethods.appleButton,
          actionType: actionTypes.success,
        });
        // send a recommended GA event that auth was successful
        nuxtApp.$bexGtag.sendLoginEvent({
          method: authMethods.appleButton,
        });

        await store.dispatch('user/reAuthenticate');
      } catch (e) {
        logErrorToSentry('signIn', e);
      } finally {
        store.dispatch('loading/finish');
        store.dispatch('modal/close');
      }
    }

    /**
     * Calls the BEX API to authenticate the user with Apple.
     *
     * @param {String} idToken: token that's return by Apple after successful login
     * @param {string} authMethod
     */
    async function loginWithApi(data) {
      if (!data) {
        logErrorToSentry('appleLogin', 'No data provided');
        throw new Error('Failed to login with Apple');
      }

      const { $sentry } = useNuxtApp();
      const service = useAppleAuthService();
      const authorizationCode = data?.authorization?.code;
      const firstName = data?.user?.name?.firstName;
      const lastName = data?.user?.name?.lastName;

      const result = await service.loginWithApple(authorizationCode, firstName, lastName, redirectURI, new RequestTimer('login-with-apple'), $sentry)
      return result;
    }

    /**
     * Takes the function name and the error object and logs it to Sentry
     */
    function logErrorToSentry(functionName, error) {
      if (process.env.logDebug) {
        console.error(`AppleAuth::${functionName}`, error);
      } else {
        const errorToLog = new AppleAuthError(`AppleAuth::${functionName}`, error);
        useNuxtApp().$sentry?.captureException(errorToLog);
      }
    }

    return {
      provide: {
        appleAuth: {
          addScript,
          signIn,
        },
      },
    };
  },
});

class AppleAuthError extends Error {
  constructor(message, originalError) {
    super(`${message}: ${originalError?.message}`);
    this.name = 'AppleAuthError';
    this.originalError = originalError;

    // Retain the stack trace of the original error
    if (originalError?.stack) {
      this.stack += `\nCaused by: ${originalError.stack}`;
    }
  }
}
