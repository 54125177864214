import FeathersService from '~/services/base/feathers-service';

export class AppleAuthService extends FeathersService {
  /**
   * Authenticates the user with Apple
   * @param {Object} formData
   * @param {RequestTimer} requestTimer
   * @param {Object} $sentry sentry instance
   */
  async loginWithApple(authorizationCode, firstName, lastName, redirectURI, requestTimer, $sentry) {
    const formData = {
      authorizationCode: authorizationCode,
      platform: 'web', // This should be hard coded to 'web'
      firstName: firstName,
      lastName: lastName,
      conversionPage: window.location.href,
      redirectURI: redirectURI,
    }
    return await super.throwableRequest(async (baseQuery) => {
      return await this.service.create({
        ...formData,
      }, {
        query: {
          ...baseQuery,
        },
      });
    }, 'loginWithApple', requestTimer, null, $sentry);
  }
}
